import React, { useState } from 'react'
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import ProfileContent from './ProfileContent';
import './Profile.css'
// import './Explore.css'
// import './ProfileSetting.css'


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: '0px',
  color: theme.palette.text.secondary,
}));

const useStyles = makeStyles({
  footercls: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
    padding: '0px !important',
    '@media (max-width: 991.98px)': {
      padding: '30px 0 !important',
    }
  },
  headercls: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
  },

  bodyclass: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
  }
});

const Profile = () => {

  const classes = useStyles();

  const [menuOpen, setMenuOpen] = useState(true)

  const openMenuHandler = () => {
    setMenuOpen(false)
  }

  const closeMenuHandler = () => {
    setMenuOpen(true)
  }

  return (
    <div className='dashboard-page profile-page-main'>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Item className={classes.bodyclass}>
              <ProfileContent />
            </Item>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}

export default Profile
