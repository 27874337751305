import React, { useEffect, useState } from 'react'
import Header from '../../app/Header'
import { Grid } from '@mui/material'
import './Staking.css'
import img1 from "../../img/wall.jpg"
import img2 from "../../img/kakashi.jpg"
import { Button } from '@mui/material'
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import useContractProviderHook from "../../actions/contractProviderHook";
import { useSelector } from "react-redux";
import erc20Abi from '../../Abi/erc20.json'
import config from '../../config/config'
import { toast } from "react-toastify";
// import { GetTopCollections } from "../../src/actions/axioss/nft.axios.js"
import { GetTopCollections, createStakeContract, createStakingPool, ListNFts, Token_NftIds } from "../../../src/actions/axioss/nft.axios"
import Web3 from 'web3'
import { NumbersOutlined } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom';
import Magic from '../../img/magic-clown.png'
import Link from '@mui/material/Link';
import Xdc from '../../img/xdclogo.png'
import Backdrop from '@mui/material/Backdrop';


const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});


function Staking() {
    const { web3p, web3, accountAddress } = useSelector(
        (state) => state.LoginReducer.AccountDetails
    );

    const navigate = useNavigate()
    const [age, setAge] = React.useState("Day");

    const [selectColleciton, setSelectCollection] = React.useState();
    const [page, setPage] = useState(1)

    //stakingDetails
    const [selColl, setSelColl] = useState("")
    const [selcCollec, setSelcCollec] = useState("")
    const [lockPeriod, setLockPeriod] = useState("")
    const [lockPre, setLockPre] = useState("")
    // const [lockMon, setLockMon] = useState("")
    const [timePollIni, setTimePollIni] = useState("")


    //stakingDetailsErr
    const [selCollErr, setSelCollErr] = useState("")
    const [lockPeriodErr, setLockPeriodErr] = useState()
    const [timePollIniErr, setTimePollIniErr] = useState()

    //RewardDetails
    const [rewardToken, setRewardToken] = useState("")
    const [rewardTokenName, setRewardTokenName] = useState("")
    const [rewardRate, setRewardRate] = useState("")
    const [rewardFreq, setRewardFreq] = useState("")
    const [rewardType, setRewardType] = useState("")
    const [rewardStartTime, setRewardStartTime] = useState("")

    //RewardDetailsErr
    const [rewardTokenErr, setRewardTokenErr] = useState("")
    const [rewardRateErr, setRewardRateErr] = useState("")
    const [rewardFreqErr, setRewardFreqErr] = useState("")
    const [rewardTypeErr, setRewardTypeErr] = useState("")
    const [rewardStartTimeErr, setRewardStartTimeErr] = useState("")

    const [isLoading, setIsLoading] = useState(false)

    const [open, setOpen] = React.useState(false);

    const toTimestamp = (strDate) => {
        const dt = Date.parse(strDate);
        return dt / 1000;
    }
    //   console.log(toTimestamp('02/13/2020 23:31:30'));


    function isValidETHAddress(str) {
        // Regex to check valid
        // BITCOIN Address
        let regex = new RegExp(/^(0x)?[0-9a-fA-F]{40}$/);

        // if str
        // is empty return false
        if (str == null) {
            return "False";
        }

        // Return true if the str
        // matched the ReGex
        if (regex.test(str) == true) {
            return "True";
        }
        else {
            return "False";
        }
    }

    const handleTimeChange = async (date) => {
        var dat;
        var month;
        var hr;
        var min;
        if ((date?.$M + 1)?.toString()?.length == 1) {
            month = `0${date?.$M + 1}`
        } else {
            month = date?.$M
        }
        if ((date?.$D)?.toString()?.length == 1) {
            dat = `0${date?.$D}`
        } else {
            dat = date?.$D
        }
        if ((date?.$H)?.toString()?.length == 1) {
            hr = `0${date?.$H}`
        } else {
            hr = date?.$H
        }
        if ((date?.$m)?.toString()?.length == 1) {
            min = `0${date?.$m}`
        } else {
            min = date?.$m
        }
        const dates = await toTimestamp(`${month}/${dat}/${date?.$y}  ${hr}:${min}`)
        setTimePollIni(dates)
    }

    const handleTimeChange1 = async (date) => {
        var dat;
        var month;
        var hr;
        var min;
        if ((date?.$M + 1)?.toString()?.length == 1) {
            month = `0${date?.$M + 1}`
        } else {
            month = date?.$M
        }
        if ((date?.$D)?.toString()?.length == 1) {
            dat = `0${date?.$D}`
        } else {
            dat = date?.$D
        }
        if ((date?.$H)?.toString()?.length == 1) {
            hr = `0${date?.$H}`
        } else {
            hr = date?.$H
        }
        if ((date?.$m)?.toString()?.length == 1) {
            min = `0${date?.$m}`
        } else {
            min = date?.$m
        }
        const dates = await toTimestamp(`${month}/${dat}/${date?.$y}  ${hr}:${min}`)
        setRewardStartTime(dates)
    }
    const ContractCall = useContractProviderHook();
    const handleChange = (event) => {
        setAge(event.target.value);
    };
    const handleCollection = (event) => {
        setSelColl(event)
    }
    const [imageUrl, setImageUrl] = useState(null);


    useEffect(() => {
        if (age != '' && lockPre != '') {
            if (age == "Day") {
                setLockPeriod(lockPre * 86400)
            } else if (age == "Month") {
                setLockPeriod(lockPre * 2629743)
            } else if (age == "Year") {
                setLockPeriod(lockPre * 31556926)
            }

        }
    }, [age, lockPre])

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            setImageUrl(reader.result);
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const checkStakingContract = async () => {
        try {
            setIsLoading(true)
            setOpen(true)
            const data = await Token_NftIds({ collectionName: "NicoleMcleod88" }) //collection name
            var ages = "Day"      // time period
            var lockPeriod1 = 0   // lock period
            var lockPre1 = 30
            if (ages == "Day") {
                lockPeriod1 = lockPre1 * 86400
            } else if (ages == "Month") {
                lockPeriod1 = lockPre1 * 2629743
            } else if (ages == "Year") {
                lockPeriod1 = lockPre1 * 31556926
            }

            var contractAddress = "0x85d216d87c993c250a7725af8f6c161d0504c32b"  // contract address
            var ownerAddress = "0x96632f6a945d1bacc008b1a7a09c81b51ac07f5e" // owneraddress
            const now = new Date();
            const fiveMinutesLater = new Date(now.getTime() + (5 * 60 * 1000));
            const timestamp = fiveMinutesLater.getTime();

            var rewardToken1 = "0x3906d89e4d29ecb47db33ca72d356246b7a7cbec"     // rewardToken address
            var rewardRate1 = "200000"      // rewardrate 
            var rewardFreq1 = "2629743"       // rewardFreq
            var rewardType1 = "token"  // reward type
            var rewardStartTime1 = timestamp  // rewardStartTime

            if (data?.data?.length > 0) {
                var nftTokens = []

                var BackValues = []
                for (let i = 0; i < data?.data?.length; i++) {
                    const element = data?.data[i];
                    nftTokens.push((element?.NFTId).toString())
                    BackValues.push((Math.floor(100000 + Math.random() * 900000)).toString())
                }
                console.log("🚀 ~ checkStakingContract ~ nftTokens:", nftTokens?.slice(500, 600))
                console.log("🚀 ~ checkStakingContract ~ nftTokens:", nftTokens?.slice(600, 700))
                console.log("🚀 ~ checkStakingContract ~ nftTokens:", nftTokens?.slice(700, 800))
                console.log("🚀 ~ checkStakingContract ~ nftTokens:", nftTokens?.slice(800, 900))
                // var contractDeploy = [contractAddress, nftTokens?.slice(800, 908), BackValues?.slice(800, 908), lockPeriod1.toString(), timestamp.toString()]
                // console.log("🚀 ~ StartStaking ~ contractDeploy:", contractDeploy)
                // // var payloads = ['0x51cd989e1163abf8262bfcd7eb782d6ce1c1074a', [1710404327], [232], "300", "1710422300"]
                // const datass = await createStakeContract()
                // console.log("🚀 ~ StartStaking ~ datass:", datass)
                // const deploy = await ContractCall.CreateStakingContract(datass, contractDeploy)
                // console.log("🚀 ~ StartStaking ~ deploy:", deploy)
                // if (deploy) {
                //     var rewards = rewardToken1
                //     if (rewardToken1 && rewardToken1?.includes('xdc')) {
                //         rewards = `0x${rewardToken1?.split('xdc')[1]}`
                //     }
                //     console.log("🚀 ~ StartStaking ~ rewards:", rewards)
                //     var rewardPayload = [[rewards], [Number(rewardRate1)], [Number(rewardFreq1)], [rewardType1 == "token" ? 1 : 0], [rewardStartTime1]]
                //     console.log("🚀 ~ checkStakingContract ~ rewardPayload:", rewardPayload)
                //     const setReward = await ContractCall.setrewards(deploy?.address, rewardPayload)
                //     if (setReward) {
                //         const chagneOwner = await ContractCall.changePoolOwner(deploy?.address, ownerAddress) //owner address
                //         console.log("🚀 ~ checkStakingContract ~ chagneOwner:", chagneOwner)
                //         console.log(data, "data?.data?._id")
                //         var payload = {
                //             collectionId: "64662d1884f6bd976226f869",
                //             lockPeriod: lockPeriod1,
                //             rewards: [{
                //                 addressContract: rewards,
                //                 type: rewardType1,
                //                 name: rewardTokenName,
                //                 amount: rewardRate1,
                //                 rewardFrecuency: rewardFreq1
                //             }],
                //             nftsStakeables: nftTokens?.slice(800, 908),
                //             nftsBackedValues: BackValues?.slice(800, 908),
                //             walletAddress: deploy?.address,
                //             createdAt: Date.now(),
                //             CollectionName: "NicoleMcleod88"
                //         }
                //         const apis = await createStakingPool(payload)
                //         console.log("🚀 ~ StartStaking ~ apis:", apis)
                //         if (apis.status == true) {
                //             setIsLoading(false)
                //             setOpen(false)
                //             toast.success("Successfully Staked")
                //             navigate(`/my-item/${accountAddress}`)
                //         }
                //     } else {
                //         setIsLoading(false)
                //         setOpen(false)
                //         toast.error("SetReward Error")
                //     }
                // } else {
                //     setIsLoading(false)
                //     setOpen(false)
                //     toast.error("Staking Error")
                // }
            }



            else {
                setIsLoading(false)
                setOpen(false)
                toast.error("No Nfts Availabel in Collection")
            }
        } catch (error) {
            console.log("🚀 ~ checkStakingContract ~ error:", error)

        }
    }



    // useEffect(() => {
    //     getUsdPrice("BASE")
    // }, [])

    const StartStaking = async () => {
        try {
            if (selColl == "") {
                setSelCollErr("Please Select Collection")
            } else if (lockPeriod == "") {
                setLockPeriodErr("Please Enter Lock Period")
            } else if (timePollIni == "") {
                setTimePollIniErr("Please Enter Start Time")
            } else if (rewardType == "") {
                setRewardTypeErr("Please Select Reward Type")
            }
            else if (rewardToken == "") {
                setRewardTokenErr("Please Enter Reward Token")
            } else if (rewardRate == "") {
                setRewardRateErr("Please Enter Reward Rate")
            } else if (rewardFreq == "") {
                setRewardFreqErr("Please Enter Reward Frequency")
            } else if (rewardStartTime == "") {
                setRewardStartTimeErr("Please Select Start Time")
            }
            else {
                setIsLoading(true)
                setOpen(true)
                const data = await Token_NftIds({ collectionName: selColl })

                if (data?.data?.length > 0) {
                    var nftTokens = []
                    var BackValues = []
                    for (let i = 0; i < data?.data?.length; i++) {
                        const element = data?.data[i];
                        nftTokens.push(Number(element?.NFTId))
                        BackValues.push(Math.floor(100000 + Math.random() * 900000))
                    }
                    var contractDeploy = [selcCollec?.contractAddress, nftTokens, BackValues, lockPeriod.toString(), timePollIni.toString()]
                    console.log("🚀 ~ StartStaking ~ contractDeploy:", contractDeploy)
                    var payloads = ['0x51cd989e1163abf8262bfcd7eb782d6ce1c1074a', [1710404327], [232], "300", "1710422300"]

                    // var gatPrice = await getUsdPrice(selcCollec?.network);
                    // console.log("🚀 ~ StartStaking ~ gatPrice:", gatPrice)
                    // var amount = 0.0001
                    // if (gatPrice != false) {
                    //     amount = 0.05 / Number(gatPrice)
                    // }
                    // console.log(amount, "amountsfdyu")

                    // var sendAmount = await ContractCall.SendRewardToAdmin(selcCollec?.network, amount)
                    // if (sendAmount != false) {
                    const datass = await createStakeContract()
                    console.log("🚀 ~ StartStaking ~ datass:", datass)
                    const deploy = await ContractCall.CreateStakingContract(datass, contractDeploy)
                    console.log("🚀 ~ StartStaking ~ deploy:", deploy)
                    if (deploy) {
                        var rewards = rewardToken
                        if (rewardToken && rewardToken?.includes('xdc')) {
                            rewards = `0x${rewardToken?.split('xdc')[1]}`
                        }
                        console.log("🚀 ~ StartStaking ~ rewards:", rewards)
                        var rewardPayload = [[rewards], [Number(rewardRate)], [Number(rewardFreq)], [rewardType == "token" ? 1 : 0], [rewardStartTime]]
                        const setReward = await ContractCall.setrewards(deploy?.address, rewardPayload)
                        if (setReward) {
                            var payload = {
                                collectionId: selcCollec?._id,
                                lockPeriod: lockPeriod,
                                rewards: [{
                                    addressContract: rewards,
                                    type: rewardType,
                                    name: rewardTokenName,
                                    amount: rewardRate,
                                    rewardFrecuency: rewardFreq
                                }],
                                nftsStakeables: nftTokens,
                                nftsBackedValues: BackValues,
                                walletAddress: deploy?.address,
                                createdAt: Date.now(),
                                CollectionName: selColl
                            }
                            const apis = await createStakingPool(payload)
                            console.log("🚀 ~ StartStaking ~ apis:", apis)
                            if (apis.status == true) {
                                setIsLoading(false)
                                setOpen(false)
                                toast.success("Successfully Staked")
                                navigate(`/my-item/${accountAddress}`)
                            }
                        } else {
                            setIsLoading(false)
                            setOpen(false)
                            toast.error("SetReward Error")
                        }

                    } else {
                        setIsLoading(false)
                        setOpen(false)
                        toast.error("Staking Error")
                    }
                    // } else {
                    //     setIsLoading(false)
                    //     toast.error("Admin Fee Err")
                    // }
                } else {
                    setIsLoading(false)
                    setOpen(false)
                    toast.error("No Nfts Availabel in Collection")
                }

            }
        } catch (error) {
            setIsLoading(false)
            setOpen(false)
            console.log("🚀 ~ StartStaking ~ error:", error)
        }
    }

    const getNameOfContract = async (rpc, data) => {
        const WEB = new Web3(rpc)
        const contractInstance = new WEB.eth.Contract(erc20Abi, data)
        const name = await contractInstance.methods.symbol().call({ from: accountAddress })
        setRewardTokenName(name)
    }

    useEffect(() => {
        var tokenss
        if (rewardToken.split('xdc').length == 2) {
            tokenss = `0x${rewardToken.split('xdc')[1]}`
        } else {
            tokenss = rewardToken
        }
        if (tokenss == "0x0000000000000000000000000000000000000000") {
            if (selcCollec?.network == "BOBA_BNB") {
                setRewardTokenName("BOBA")
            } else if (selcCollec?.network == "BOBA_BNB") {
                setRewardTokenName("BOBA")
            } else if (selcCollec?.network == "BOBA") {
                setRewardTokenName("ETH")
            } else if (selcCollec?.network == "BASE") {
                setRewardTokenName("BASE")
            } else if (selcCollec?.network == "ETH") {
                setRewardTokenName("ETH")
            } else if ((selcCollec?.network == "XDC" || selcCollec?.network == "")) {
                setRewardTokenName("XDC")
            }

        } else if (tokenss != "") {
            const data = isValidETHAddress(tokenss)
            if (data == 'True' && web3) {
                if (selcCollec?.network == "BOBA_BNB") {
                    getNameOfContract(config.BOBA_BNB_RPC_URL, tokenss)
                } else if ((selcCollec?.network == "XDC" || selcCollec?.network == "")) {
                    getNameOfContract(config.RPC_URL, tokenss)
                } else if (selcCollec?.network == "ETH") {
                    getNameOfContract(config.SOP_RPC_URL, tokenss)
                } else if (selcCollec?.network == "BASE") {
                    getNameOfContract(config.BASE_RPC_URL, tokenss)
                } else if (selcCollec?.network == "BOBA") {
                    getNameOfContract(config.BOBA_RPC_URL, tokenss)
                }

            } else {
                setRewardTokenName("")
            }
        }
        else {
            setRewardTokenName("")
        }
    }, [rewardToken])


    const getDatas = async (mintdata) => {
        try {
            const data = await createStakeContract()
            //[nftcontract, [NFT id],[backvalues],lockperiod in timestamp like 1day, 1month, time of pool initialize]
            var payload = ['0x51cd989e1163abf8262bfcd7eb782d6ce1c1074a', [1710404327], [232], 300, 1710422300]
            const deploy = await ContractCall.CreateStakingContract(data, payload)
            const paylo = {
                "collectionId": "",
                "lockPeriod": "",
                "rewards": [
                    {
                        "addressContract": "0x84fb1a5795858e7296780a7f55c43afa20e9a7ae",
                        "type": "token",
                        "name": "GEM",
                        "color": "#781FD1",
                        "amount": "1",
                        "rewardFrecuency": "720"
                    }
                ],
                "nftsStakeables": ["1710404327"],
                "nftsBackedValues": ["232"],
                "isBackedValue": false,
                "walletAddress": "0x52307495A627c14DF5590556b0F4cFd994334b14",   //staking contract address
                "createdAt": "1710410334",
                "CollectionName": "staking collection"
            }
            const apis = await createStakingPool(paylo)
        } catch (error) {
            console.log("🚀 ~ getDatas ~ error:", error)

        }
    }


    const GetCollections = async () => {
        // console.log("location", Creator)
        // setIsload(true)
        var querydata
        // if(TabName === "All"){
        //   querydata ={}
        setSelectCollection([])
        // }else{
        querydata = { filter: "mycollections", page: page, Creator: accountAddress, sortfilter: "Alls", skip: 0 }
        // }
        // console.log("querydata", querydata);
        var list = await GetTopCollections(querydata)
        var arr = []
        for (let i = 0; i < list?.data?.length; i++) {
            const element = list?.data[i];
            if (!element?.isStakeable) {
                arr.push({ _id: element?._id, collectionName: element?.collectionName, network: element?.network, contractAddress: element?.contractAddress })
            }
        }
        setSelectCollection(arr)
        // console.log("list?.data", list)
        // setIsload(false)
    }
    useEffect(() => {
        GetCollections()
    }, [accountAddress])


    const [imageUrlBanner, setImageUrlBanner] = useState(null);

    const handleImageUploadBanner = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            setImageUrlBanner(reader.result);
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const containerStyle = {
        position: 'relative',
        // Adjust as needed
    };

    const buttonContainerStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        opacity: 0, // Initially hidden
        transition: 'opacity 0.3s ease',
    };

    const buttonStyle = {
        color: '#fff',
        // padding: '30px',
        border: 'none',
        cursor: 'pointer',
    };

    const containerStyle2 = {
        position: 'relative',
        // Adjust as needed
        width: 'fit-content'
    };

    const buttonContainerStyle2 = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        opacity: 0, // Initially hidden
        transition: 'opacity 0.3s ease',
    };

    const buttonStyle2 = {
        color: '#fff',
        // padding: '30px',
        border: 'none',
        cursor: 'pointer',
    };
    const handleMouseEnter = () => {
        document.getElementById('buttonContainer').style.opacity = '1';
    };

    const handleMouseLeave = () => {
        document.getElementById('buttonContainer').style.opacity = '0';
    };

    const handleMouseEnter2 = () => {
        document.getElementById('buttonContainer2').style.opacity = '1';
    };

    const handleMouseLeave2 = () => {
        document.getElementById('buttonContainer2').style.opacity = '0';
    };

    return (
        <div className='staking opacity' >
            <Grid container spacing={0}>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Header />
                </Grid>
            </Grid>
            <Grid>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12} className='opacity'>
                    <div className='staking-wizard'>
                        <p>The Creator Staking Wizard enables creators to power up their  collections on XDSea  via implementing staking rewards for their NFTs.</p>
                    </div>
                    <div className='let-magic'>
                        <p>Lets do the <span>magic</span></p>
                    </div>
                </Grid>
            </Grid>
            <Grid container spacing={0} sx={{ justifyContent: 'center' }} >
                {/* <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className='staking__title'>
                        <div className='image-banner' style={containerStyle} onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}>
                            <img src={imageUrlBanner ? imageUrlBanner : img1} alt='img1' className='stk-banner' />
                            <div id="buttonContainer" style={buttonContainerStyle}>
                                <Button
                                    component="label"
                                    role={undefined}
                                    tabIndex={-1}
                                    onChange={handleImageUploadBanner}
                                    startIcon={<CloudUploadIcon />}
                                    style={buttonStyle}
                                    variant='contained'
                                >
                                    <VisuallyHiddenInput type="file" />Upload Banner
                                </Button>
                            </div>
                        </div>
                        <div className='profile-img'>
                            <div style={containerStyle2} onMouseEnter={handleMouseEnter2}
                                onMouseLeave={handleMouseLeave2}>
                                <img src={imageUrl ? imageUrl : img2} alt='img1' className='stk-img' />
                                <div id="buttonContainer2" style={buttonContainerStyle2}>
                                    <Button
                                        component="label"
                                        role={undefined}
                                        tabIndex={-1}
                                        onChange={handleImageUpload}
                                        startIcon={<CloudUploadIcon />}
                                        style={buttonStyle2}

                                    >
                                        <VisuallyHiddenInput type="file" />
                                    </Button>
                                </div>
                            </div>
                        </div>

                    </div>
                </Grid> */}
                <Grid xs={12} sm={12} md={12} lg={8.5} xl={6.5} className='shadow'>
                    <Grid container spacing={0} >

                        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className='logo-part'>
                                <img src={Xdc} />
                            </div>
                            <div className='form-group'>
                                Nft Details:
                            </div>

                        </Grid>
                        <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                            <div className='form-control-nft-name'>
                                Select Collection:
                                <p>(select the collection you want to add magic to)</p>
                            </div>
                        </Grid>

                        <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                            <div className='drop-down'>
                                {/* <input type='next' /> */}
                                <FormControl sx={{ minWidth: '80%' }} >
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={selColl}
                                    // onChange={handleCollection}
                                    >
                                        {/* <MenuItem value={"No Collection"} onClick={() => { handleCollection("No Collection") }} >No Collection</MenuItem> */}
                                        {selectColleciton?.length == 0 &&
                                            <MenuItem >No Collection Available</MenuItem>}

                                        {
                                            selectColleciton?.length > 0 && selectColleciton?.map((item, index) => {

                                                return <MenuItem value={item?.collectionName} onClick={() => { handleCollection(item?.collectionName); setSelcCollec(item); setSelCollErr("") }} >{item?.collectionName}</MenuItem>
                                            }

                                            )
                                        }

                                    </Select>
                                </FormControl>
                            </div>
                            {selCollErr != "" ? <div style={{ color: 'red', textAlign: 'center' }}>{selCollErr}</div> : <></>}
                        </Grid>
                        {/* <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                            <div className='form-control-nft-name'>
                                NFT Address:
                            </div>
                        </Grid>

                        <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                            <div className='input-field'>
                                <input type='next' />
                            </div>
                        </Grid> */}
                        <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                            <div className='form-control-nft-name'>
                                Lock Period:
                                <p>(Select the amount of days/month/year you want the funds to be locked before users can claim it)</p>
                            </div>
                        </Grid>

                        <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                            <div className='input-field drop-down xl day-drop'>
                                <input type='next' onChange={(e) => { setLockPre(e.target.value); setLockPeriodErr("") }} />
                                <FormControl sx={{ minWidth: 'auto' }} >
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={age}
                                        onChange={handleChange}

                                    >
                                        <MenuItem value={"Day"}>Day</MenuItem>
                                        <MenuItem value={"Month"}>Month</MenuItem>
                                        <MenuItem value={"Year"}>Year</MenuItem>

                                    </Select>
                                </FormControl>
                            </div>
                            {lockPeriodErr != "" ? <div style={{ color: 'red', textAlign: 'center' }}>{lockPeriodErr}</div> : <></>}
                        </Grid>
                        <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                            <div className='form-control-nft-name'>
                                Time of Pool Initalization:
                                <p>(Select date and time for when you want staking to begin)</p>
                            </div>
                        </Grid>

                        <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                            <div className='dateTimePicker'>
                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                    <DemoContainer components={['DateTimePicker']}>
                                        <DateTimePicker disablePast onChange={(e) => { handleTimeChange(e); setTimePollIniErr("") }} />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </div>
                            {timePollIniErr != "" ? <div style={{ color: 'red', textAlign: 'center' }}>{timePollIniErr}</div> : <></>}
                        </Grid>
                        {/* <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                            <div className='form-control-nft-name'>
                                Token IDs:
                            </div>
                        </Grid>

                        <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                            <div className='input-field'>
                                <input type="number"
                                    inputMode="numeric" />
                                <p className='pa-stk'>*please enter valid token</p>
                            </div>
                        </Grid> */}







                    </Grid>
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={8.5} xl={6.5} className='shadow'>
                    <Grid container spacing={0} sx={{ justifyContent: 'center' }}>
                        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className='form-group'>
                                Reward Details:
                            </div>

                        </Grid>

                        <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                            <div className='form-control-nft-name'>
                                Reward Type:
                            </div>
                        </Grid>
                        <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                            <div className='drop-down'>
                                <Box >
                                    <FormControl sx={{ minWidth: 360 }} >
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={rewardType}
                                        // onChange={handleChange}
                                        >
                                            <MenuItem value={"coin"} onClick={() => { setRewardType("coin"); setRewardToken('0x0000000000000000000000000000000000000000'); setRewardTypeErr(""); setRewardTokenErr("") }} >Coin</MenuItem>
                                            <MenuItem value={"token"} onClick={() => { setRewardType("token"); setRewardToken(""); setRewardTypeErr("") }}>Token</MenuItem>

                                        </Select>
                                    </FormControl>
                                </Box>
                            </div>
                            {rewardTypeErr != "" ? <div style={{ color: 'red', textAlign: 'center' }}>{rewardTypeErr}</div> : <></>}
                        </Grid>


                        <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                            <div className='form-control-nft-name'>
                                Reward Token Address:
                                <p>(The contract address of the reward token.For example, if you want to give rewards in $Eth enter $Eth smart contract address)</p>
                            </div>
                        </Grid>
                        <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                            <div className='input-field'>
                                <input type='next' disabled={rewardType == 'coin' ? true : false} value={rewardToken} onChange={(e) => { setRewardToken(e.target.value); setRewardTokenErr("") }} />
                            </div>
                            {rewardTokenErr != "" ? <div style={{ color: 'red', textAlign: 'center' }}>{rewardTokenErr}</div> : <></>}
                        </Grid>

                        <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                            <div className='form-control-nft-name'>
                                Reward Rate:
                                <p>(The amount of tokens users can claim)</p>
                            </div>
                        </Grid>
                        <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                            <div className='input-field'>
                                <input type="number"
                                    inputMode="numeric" onChange={(e) => { setRewardRate(e.target.value); setRewardRateErr("") }} />
                            </div>
                            {rewardRateErr != "" ? <div style={{ color: 'red', textAlign: 'center' }}>{rewardRateErr}</div> : <></>}
                        </Grid>

                        <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                            <div className='form-control-nft-name'>
                                Reward Frequency:
                                <p>(How often can users claim this reward note: Please enter in seconds, if selecting monhtly rewards, that's 2,629,743 seconds</p>
                            </div>
                        </Grid>
                        <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                            <div className='input-field'>
                                <input type="number"
                                    inputMode="numeric" onChange={(e) => { setRewardFreq(e.target.value); setRewardFreqErr("") }} />
                            </div>
                            {rewardFreqErr != "" ? <div style={{ color: 'red', textAlign: 'center' }}>{rewardFreqErr}</div> : <></>}
                        </Grid>


                        <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                            <div className='form-control-nft-name'>
                                Start the Time
                            </div>
                        </Grid>
                        <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                            <div className='dateTimePicker'>
                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                    <DemoContainer components={['DateTimePicker']}>
                                        <DateTimePicker disablePast onChange={(e) => { handleTimeChange1(e); setRewardStartTimeErr("") }} />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </div>
                            {rewardStartTimeErr != "" ? <div style={{ color: 'red', textAlign: 'center' }}>{rewardStartTimeErr}</div> : <></>}
                        </Grid>
                    </Grid>
                </Grid>

                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className='sktbtn'>
                        {/* <Button variant='contained' onClick={() => { StartStaking() }} >Start Staking</Button> */}
                        {isLoading == true ?
                            <Button variant='contained' disabled >Loading...</Button>
                            :
                            <Button variant='contained' onClick={() => { StartStaking() }} > Start the Magic</Button>
                        }

                        {/* <Button variant='contained' onClick={() => { checkStakingContract() }} > Staking</Button> */}
                    </div>
                </Grid>

                <Grid xs={12} sm={12} md={12} lg={8.5} xl={6.5}>
                    <Grid container spacing={0} sx={{ justifyContent: 'center' }}>
                        <Grid xs={12} sm={12} md={12} lg={8} xl={8}>
                            <div className='need-help'>
                                <p>Still need help?</p>
                                <p>Check out  our staking wizard guide <Link>here</Link></p>
                            </div>
                        </Grid>
                        <Grid xs={12} sm={12} md={12} lg={4} xl={4}>
                            <div className='magic-clown'>
                                <img src={Magic} />
                            </div>
                        </Grid>
                    </Grid>

                </Grid>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, backdropFilter: 'blur(3px)' }}
                    open={open}
                // onClick={handleClose}
                >
                    {/* <CircularProgress color="inherit" /> */}
                </Backdrop>

            </Grid>
        </div>
    )
}

export default Staking
