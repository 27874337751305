import React, { useState, useEffect } from 'react'
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useNavigate, useLocation, useParams } from "react-router-dom"
import collection1 from "../img/collection-1.png";
import collection2 from "../img/collection-2.webp";
import collection3 from "../img/collection-3.webp";
import collection4 from "../img/collection-4.jpg";
import collection5 from "../img/collection-5.webp";
import collection6 from "../img/collection-6.png";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { GetTopCollections } from '../actions/axioss/nft.axios';

function createData(
  rank,
  collectionimg,
  collectionname,
  floorprice,
  volume,
  volpercent
) {
  return {
    rank,
    collectionimg,
    collectionname,
    floorprice,
    volume,
    volpercent,
  };
}

const rowsone = [
  createData(1, collection1, "collection1"),
  createData(2, collection2, "collection2"),
  createData(3, collection3, "collection3"),
  createData(4, collection4, "collection4"),
  createData(5, collection5, "collection5"),
];

function createDatatwo(
  ranktwo,
  collectionimgtwo,
  collectionnametwo,
  floorpricetwo,
  volumetwo,
  volpercenttwo
) {
  return {
    ranktwo,
    collectionimgtwo,
    collectionnametwo,
    floorpricetwo,
    volumetwo,
    volpercenttwo,
  };
}

const rowstwo = [
  createDatatwo(6, collection6, "collection6"),
  createDatatwo(7, collection3, "collection3"),
  createDatatwo(8, collection4, "collection4"),
  createDatatwo(9, collection5, "collection5"),
  createDatatwo(10, collection6, "collection6"),
];

const ExploreTableTop = ({ topCollectionsList }) => {

  const navigate = useNavigate();

  const [chains, setChains] = React.useState("");
  const midIndex = Math.ceil(topCollectionsList?.length / 2);
  const FirstHalf = topCollectionsList?.slice(0, midIndex);
  const SecondHalf = topCollectionsList?.slice(midIndex);

  const handleChainChange = (event) => {
    setChains(event.target.value);
  };

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.white,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[6],
      borderRadius: 5,
      fontSize: 12,
      padding: 8,
    },
  }));

  const LightTooltipImage = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.white,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[6],
      borderRadius: 5,
      fontSize: 12,
      padding: 2,
    },
  }));
  const { category } = useParams()


  const Tabname = (newValue) => {
    SetTabName(newValue);
    setSkip(0)
    setCollectionList([])
    setTabArray([])

  }

  const [page, setPage] = useState(1)
  const [collectionList, setCollectionList] = useState([])
  const [LoadMore, setLoadMore] = useState(true)
  const location = useLocation();
  const { Creator } = useParams()
  const [hasMoreForcol, setHasmoreForCol] = useState(true)
  const [TabName, SetTabName] = useState("All");
  const [skip, setSkip] = useState(0)
  const [TabArray, setTabArray] = useState(category ? [category] : [])





  useEffect(() => {
    GetCollections()
  }, [])



  const GetCollections = async () => {

    var querydata
    querydata = (location.pathname.includes("mycollections")) ? { filter: "mycollections", page: page, Creator: Creator, sortfilter: TabName, skip } : { filter: "topcollections", page: page, sortfilter: TabName, skip }

    var list = await GetTopCollections(querydata)

    if (list?.status) (collectionList.length == 0) ? setCollectionList(list?.data) : setCollectionList(collectionList => [...collectionList, ...list?.data])

    if (list?.status && list?.data.length < 15) setLoadMore(false)
    if (list?.data.length == 0) { setLoadMore(false); setHasmoreForCol(false) }
    if (list.status && list?.data.length != 0) { collectionList.length == 0 ? setCollectionList(list?.data) : setCollectionList([...collectionList, ...list?.data]); setSkip(skip + 15); setHasmoreForCol(true) }
    else { setLoadMore(false); setHasmoreForCol(false) }

  }


  const loadMore = async () => {
    setTimeout(() => {
      GetCollections()
    }, 1000)
  }

  return (
    <div>
      <Box sx={{ flexGrow: 1 }} className="Table-overflow desktop">
        <Grid container spacing={10} className="sub-tables">
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Grid container spacing={2} className="firstContain">
              <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                <div className="rankingTheads display-none">Rank</div>
                <div className="sm-d-block">#</div>
              </Grid>
              <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                <div className="rankingTheads collect">Collection</div>
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <div className="rankingTheads display-none">
                  Collection Count
                </div>
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <div className="rankingTheads">Volume</div>
              </Grid>
            </Grid>

            {FirstHalf?.map((row, index) => (
              <Grid onClick={() => navigate(`/collection/${row.Creator}/${row.customUrl}`)} key={row?._id} container spacing={2} className="bodyRow">

                <Grid
                  item
                  xs={1}
                  sm={1}
                  md={1}
                  lg={1}
                  xl={1}
                  className="ranking"
                >
                  {index + 1}
                </Grid>
                <Grid
                  item
                  xs={5}
                  sm={5}
                  md={5}
                  lg={5}
                  xl={5}
                  className="ranking-outer"
                >
                  <div className="collections-outer">
                    <LightTooltipImage
                      // title={
                      //   <>
                      //     <div className="Collection-table-image-hover">
                      //       <Link>
                      //         <img
                      //           src={collection1}
                      //           alt="Ethereum"
                      //         />
                      //       </Link>
                      //       <Link>
                      //         <img
                      //           src={collection1}
                      //           alt="Ethereum"
                      //         />
                      //       </Link>
                      //       <Link>
                      //         <img
                      //           src={collection1}
                      //           alt="Ethereum"
                      //         />
                      //       </Link>
                      //     </div>
                      //   </>
                      // }
                      placement="top-start"
                      arrow
                    >
                      <div className="collections-img ranking">
                        <img src={row?.profileImage} />
                      </div>
                    </LightTooltipImage>
                    <span>{row?.collectionName}</span>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={3}
                  sm={3}
                  md={3}
                  lg={3}
                  xl={3}
                  className="ranking-outer"
                >
                  <div className="collections-outer">
                    <span>
                      {row?.collectionCount}
                    </span>
                  </div>

                </Grid>
                <Grid item xs={3} sm={3} md={3} lg={3} xl={3} className="ranking-outer">
                  <div className="volume-rate-outer">
                    <div className="volume-rate ranking">
                      {row?.volume} {row?.network === "" ? "XDC" : row?.network}
                    </div>

                  </div>
                </Grid>
              </Grid>
            ))}


          </Grid>

          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Grid container spacing={2} className="firstContain">
              <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                <div className="rankingTheads display-none">Rank</div>
                <div className="sm-d-block">#</div>
              </Grid>
              <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                <div className="rankingTheads collect">Collection</div>
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <div className="rankingTheads display-none">
                  collection Count
                </div>
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <div className="rankingTheads">Volume</div>
              </Grid>
            </Grid>

            {SecondHalf?.map((row, index) => (
              <Grid onClick={() => navigate(`/collection/${row.Creator}/${row.customUrl}`)} key={row?._id} container spacing={2} className="bodyRow">
                <Grid
                  item
                  xs={1}
                  sm={1}
                  md={1}
                  lg={1}
                  xl={1}
                  className="ranking"
                >
                  {index + midIndex + 1}
                </Grid>
                <Grid
                  item
                  xs={5}
                  sm={5}
                  md={5}
                  lg={5}
                  xl={5}
                  className="ranking-outer"
                >
                  <div className="collections-outer">
                    <LightTooltipImage
                    // title={
                    //   <>
                    //     <div className="Collection-table-image-hover">
                    //       <Link>
                    //         <img
                    //           src={collection1}
                    //           alt="Ethereum"
                    //         />
                    //       </Link>
                    //       <Link>
                    //         <img
                    //           src={collection1}
                    //           alt="Ethereum"
                    //         />
                    //       </Link>
                    //       <Link>
                    //         <img
                    //           src={collection1}
                    //           alt="Ethereum"
                    //         />
                    //       </Link>
                    //     </div>
                    //   </>
                    // }
                    // placement="top-start"
                    // arrow
                    >
                      <div className="collections-img ranking">
                        <img src={row?.profileImage} />
                      </div>
                    </LightTooltipImage>
                    <span>{row?.collectionName}</span>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={3}
                  sm={3}
                  md={3}
                  lg={3}
                  xl={3}
                  className="ranking-outer"
                >
                  <div className="collections-outer">
                    <span>
                      {row?.collectionCount}
                    </span>
                  </div>



                </Grid>
                <Grid item xs={3} sm={3} md={3} lg={3} xl={3} className="ranking-outer">


                  <div className="volume-rate-outer">
                    <div className="volume-rate ranking">
                      {row?.volume} {row?.network === "" ? "XDC" : row?.network}
                    </div>

                  </div>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{ flexGrow: 1 }}
        className="Table-overflow mobile-response"
        mt={5}
      >
        <Grid container spacing={2} className="sub-tables" id="table123">
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 300 }} aria-label="simple table">
                <TableHead>
                  <TableRow>

                    <TableCell align="center"><div className="collections-outers">
                      <span style={{ "fontSize": "15px" }}>Rank #</span>
                    </div></TableCell>
                    <TableCell align="center"><div className="collections-outers">
                      <span style={{ "fontSize": "15px" }}>Collection</span>
                    </div></TableCell>
                    <TableCell align="center"><div className="collections-outers">
                      <span style={{ "fontSize": "15px" }}>Collection Count</span>
                    </div></TableCell>
                    <TableCell align="center"><div className="collections-outers">
                      <span style={{ "fontSize": "15px" }}>Volume</span>
                    </div></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {topCollectionsList.map((row, index) => (
                    <TableRow onClick={() => navigate(`/collection/${row.Creator}/${row.customUrl}`)} key={row?._id}

                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >

                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">
                        <div className="collections-outer">
                          <LightTooltipImage>
                            <div className="collections-img ranking">
                              <img src={row?.profileImage} />
                            </div>
                          </LightTooltipImage>
                          <span>{row?.collectionName}</span>
                        </div>
                      </TableCell>
                      <TableCell align="center">

                        <div className="collections-outer">

                          <span>{row?.collectionCount}</span>
                        </div>

                        {/* <div className="volume-rate-outer">
                    <div className="volume-rate ranking">
                    {row?.collectionCount} 
                    </div>

                  </div> */}
                      </TableCell>
                      <TableCell align="center">

                        <div className="collections-outer">

                          <span>{row?.volume} {row?.network === "" ? "XDC" : row?.network}</span>
                        </div>

                        {/* <div className="volume-rate-outer">
                    <div className="volume-rate ranking">
                      {row?.volume} {row?.network === "" ? "XDC" : row?.network}
                    </div>

                  </div> */}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>


        </Grid>
      </Box>
    </div>
  )
}

export default ExploreTableTop
