 

import React from 'react'
import Header from '../app/Header'
import Footer from '../app/Footer'
import HomeComponent from "../app/Home"
import NewHome from '../app/NewHome'

function HomePage() {
  return (
    <>
    <Header/>
    <NewHome/>
    <Footer/>
    </>
  )
}
 
export default HomePage