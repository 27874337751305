import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from "@mui/material/Box";
import Header from '../../app/Header'
import { Button, Spinner } from 'react-bootstrap'
import { Link, useLocation, useParams } from "react-router-dom";
import useContractProviderHook from "../../actions/contractProviderHook";
import Footer from '../../app/Footer'
import {
  Earncollections,
  createStakeContract,
  createStakingPool
} from "../../actions/axioss/nft.axios";
import './Earn.css'
import collection1 from '../../img/collection-1.png'
import collection4 from '../../img/collection-4.jpg'
import actionps1 from '../../img/action-ps-1.png'
import { useEffect } from 'react';

// function createData(sno, collectionimg, collectionname, tvlimg, totalvaluelocked,totalvaluespan, rewarrateimg, rewarrate, lockperiod, rewardfrequency, rewardtokenimg, rewardtoken) {
//   return { sno, collectionimg, collectionname, tvlimg, totalvaluelocked,totalvaluespan, rewarrateimg, rewarrate, lockperiod, rewardfrequency, rewardtokenimg, rewardtoken };
// }

// const rows = [
//   createData(1, collection1, 'XDC-1', actionps1, 234567, "624.05k USD",  actionps1, 6.0, 0, 1, actionps1, 'XDC'),
//   createData(2, collection4, 'XDC-2', actionps1, 13579,"17,487,22 USD", actionps1, 9.0, 0, 1, actionps1, 'GEM'),
//   createData(3, collection1, 'XDC-3', actionps1, 2134,"0 USD", actionps1, 16.0, 0, 1, actionps1, 'XDC'),
//   createData(4, collection4, 'XDC-4', actionps1, 2678,"624.05k USD", actionps1, 3.7, 0, 1, actionps1, 'GEM'),
//   createData(5, collection1, 'XDC-5', actionps1, 3452189077,"0 USD", actionps1, 16.0, 0, 1, actionps1, 'XDC'),
// ];

export default function Earn() {
  const [earncollection, setEarncollections] = React.useState([])
  const [isload, setIsload] = React.useState(true)
  useEffect(() => {
    getEarncollections()
    // getDatas()
  }, [])

  const ContractCall = useContractProviderHook();

  const getDatas = async () => {
    try {
      const data = await createStakeContract()
      var payload = ['0x51cd989e1163abf8262bfcd7eb782d6ce1c1074a', [1710404327], [232], 300, 1710422300]
      const deploy = await ContractCall.CreateStakingContract(data, payload)
      console.log("🚀 ~ getDatas ~ deploy:", deploy)
      const paylo = {
        "collectionId": "",
        "lockPeriod": "",
        "rewards": [
          {
            "addressContract": "0x84fb1a5795858e7296780a7f55c43afa20e9a7ae",
            "type": "token",
            "name": "GEM",
            "color": "#781FD1",
            "amount": "1",
            "rewardFrecuency": "720"
          }
        ],
        "nftsStakeables": ["1710404327"],
        "nftsBackedValues": ["232"],
        "isBackedValue": false,
        "walletAddress": "0x52307495A627c14DF5590556b0F4cFd994334b14",   //staking contract address
        "createdAt": "1710410334",
        "CollectionName": "staking collection"
      }
      const apis = await createStakingPool(paylo)
      console.log("🚀 ~ getDatas ~ apis:", apis)
    } catch (error) {
      console.log("🚀 ~ getDatas ~ error:", error)

    }
  }

  const getEarncollections = async () => {
    var data = await Earncollections(); //{page:1}
    if (data?.data) {
      setEarncollections(data?.data);
      setIsload(false)
    }
  }
  return (
    <div class="earn-main-page">
      <Header />
      <div className='earn-main-page-table'>
        <h2>Earn</h2>
        {isload === true ? <div className='noitems_found_align' style={{ marginBottom: '50px' }}>
          <Button variant="primary">
            <Spinner
              as="span"
              animation="border"
              style={{ marginRight: "10px" }}
              size='sm'
              role="status"
              aria-hidden="true"
            />
            Loading...
          </Button>

        </div> :
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className="rollno" align="left"></TableCell>
                  <TableCell align="left"><div className='table-title'>Collection Name</div></TableCell>
                  <TableCell align="left"><div className='table-title'>Total Value Locked</div></TableCell>
                  <TableCell align="center"><div className='table-title'>Reward Rate</div></TableCell>
                  <TableCell align="center"><div className='table-title'>Lock Period</div></TableCell>
                  <TableCell align="center"><div className='table-title'>Reward Frequency</div></TableCell>
                  <TableCell align="right"><div className='table-title'>Reward Token</div></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {earncollection.length > 0 ? <>
                  {earncollection && earncollection.map((row, index) => (
                    <TableRow
                      key={row._id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell className="rollno" align="left">{index + 1}</TableCell>
                      <TableCell align="left">
                        <Link style={{ color: "black" }} to={
                          (row?.isImported)
                            ? `/importcollection/${row.Creator}/${row.customUrl}` : `/collection/${row.Creator}/${row.customUrl}`}>
                          <div className='collection-image-div-outer'>
                            <div className='collection-image-div'>
                              <img src={row?.profileImage} alt="" />
                            </div>{row?.collectionName}
                          </div>
                        </Link>
                      </TableCell>
                      <TableCell align="center"><div className='tvlimg-outer'><div className='tvlimg'>{row?.tokenLength}</div></div></TableCell>
                      <TableCell align="center"><div className='tvlimg-outer'><div className='tvlimg'>{row?.rewardRate}</div></div></TableCell>

                      {/* <TableCell align="left"><div className='tvlimg-outer'><div className='tvlimg'><img src={row.tvlimg} alt="tvlimg"/></div>{row.totalvaluelocked}<div className='totalvaluespan'>{row.totalvaluespan}</div></div></TableCell> */}
                      {/* <TableCell align="center"><div className='tvlimg-outer center-align'><div className='tvlimg'><img src={row.rewarrateimg} alt="tvlimg"/></div>{row.rewarrate}</div></TableCell> */}
                      <TableCell align="center">{row?.stake?.lockPeriod ? (`${row?.stake?.lockPeriod} Hrs`) : '730 Hrs'}</TableCell>
                      {/* <TableCell align="center"><div className='tvlimg-outer'><div className='tvlimg'>{row?.rewardFrequency}Mo</div></div></TableCell> */}
                      <TableCell align="center">{row?.rewardFrequency ? (`${row?.rewardFrequency}`) : ('-')}</TableCell>
                      <TableCell align="center">{row?.rewardToken ? (`${row?.rewardToken}`) : ('-')}</TableCell>

                      {/* <TableCell align="right"><div className='tvlimg-outer right-align'><div className='tvlimg'><img src={row.rewardtokenimg} alt="tvlimg"/></div>{row.rewardtoken}</div></TableCell> */}
                    </TableRow>
                  ))}
                </> : <>

                  <Box className="community community_one_align">
                    <h2 className="home_page_headings_align">No Data Found</h2>
                  </Box>

                </>}
              </TableBody>
            </Table>
          </TableContainer>
        }
      </div>
      <Footer />
    </div>
  );
}