

// import ERC721 from '../../src/Abi/erc721.json'
// import ERC1155 from '../../src/Abi/erc1155.json'
// import TRADE from '../../src/Abi/market.json'

var EnvName = 'production';

var key = {};
key.KEY = 'CardBo@rD1290%6Fine3'
key.ONEDAYINSECONDS = 0
key.BLOCKS_PER_YEAR = 0
key.RPAD_ADDRESS = ''
key.ROUTER = ''
key.EMAIL = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
key.MOBILE = /^\d{10}$/
key.NumOnly = /^\d+$/
key.PASSWORD = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/
key.OnlyAlbhabets = /^(?:[A-Za-z]+)(?:[A-Za-z0-9 _]*)$/
key.notSpecil = /^[a-zA-Z0-9]+$/
key.OnlyAlphSpecial = /^[A-Za-z_@.#&+-]*$/
key.IPFS = ' '
key.limit = 50
key.NumDigitOnly = /[^0-9\.]/g
key.NumberOnly = /[^0-9]/g
if (EnvName === "demo") {

}
else if (EnvName === "stage") {

}
else if (EnvName === "production") {
    // key.FRONT_URL = 'https://www.xdsea.com'
    key.BACK_URL = 'https://xdsea.com/api/v1/front'
    key.ADMIN_URL = 'https://xdsea.com/api/v1/admin'

    //key.BACK_URL = 'https://api.xdsea.com/v1/front'
    // key.ADMIN_URL = 'http://localhost:3003/api/v1/admin'
    // key.BACK_URL = 'http://localhost:3003/api/v1/front'

    //  key.ADMIN_URL = 'https://xdsea.com/api/v1/admin'
    key.IMG_URL = 'https://api.xdsea.com'
    key.RPC_URL = "https://rpc.xdcrpc.com/"
    key.BASE_RPC_URL = 'https://base.blockpi.network/v1/rpc/public'
    // key.BASE_RPC_URL = 'https://base-sepolia-rpc.publicnode.com'
    key.SOP_RPC_URL = 'https://eth.llamarpc.com'
    key.BOBA_RPC_URL = 'https://mainnet.boba.network'
    // key.BOBA_RPC_URL = 'https://goerli.boba.network'
    key.BOBA_BNB_RPC_URL = 'https://replica.bnb.boba.network'
    key.CHAIN_ID = 50
    key.COIN_NAME = "XDC"
    key.DEADADDRESS = '0x000000000000000000000000000000000000dEaD'
    key.erc20Address = '0x8a3cc832bb6b255622e92dc9d4611f2a94d200da'
    key.TradeContract = '0xCDcE3724fBa95c29fca1c03F22e0fC77Da3C15c1'
    key.ERC721 = '0x51cd989e1163abf8262bfcd7eb782d6ce1c1074a'
    key.ERC1155 = '0x3e50c80f8a6a173a8059830d0cf0c9e5b04c43c0'
    // key.Base_TradeContract = '0xcACAbb068D1bC1CC75Fdefa9940991B389bA1fda' //base goreli
    // key.Base_ERC721 = '0xFA7df76E0CbdFFAF3277DC46c3d35629942712bC'  //base goreli
    // key.Base_ERC1155 = '0xDA950D5C8AA60b6d3Dc432b8EFD494FBC8C48144' //base goreli
    // key.SOP_TradeContract = '0x0708D75506D37c6B25f32C9DeD233A103B8005E3'
    // key.SOP_ERC721 = '0x6a27F5ffC60BE131c8B5008370Eb3168f0F13705'
    // key.SOP_ERC1155 = '0xD5C66f20E00E61B08dd0D879A7114e17D37e128C'

    // base mainnet
    key.Base_TradeContract = '0xD5C66f20E00E61B08dd0D879A7114e17D37e128C'
    key.Base_ERC721 = '0xB6500739151f255287C945602Dfe17615FA1b8ea'
    key.Base_ERC1155 = '0x6a27F5ffC60BE131c8B5008370Eb3168f0F13705'

    // base sepolia testnet
    // key.Base_TradeContract = '0xFA7df76E0CbdFFAF3277DC46c3d35629942712bC'
    // key.Base_ERC721 = '0xDA950D5C8AA60b6d3Dc432b8EFD494FBC8C48144'
    // key.Base_ERC1155 = '0xF6223CDecAC7392999C757D220eA9fB3679608ea'

    key.SOP_TradeContract = '0xcACAbb068D1bC1CC75Fdefa9940991B389bA1fda'
    key.SOP_ERC721 = '0x89C80eb6dB58c57ED2b891909bCF07251D761d52'
    key.SOP_ERC1155 = '0xbba33949d6ff50ec4F7De8C495cc2a8F22d9F8Ec'

    key.Kroma_TradeContract = '0xF6223CDecAC7392999C757D220eA9fB3679608ea'
    key.Kroma_ERC721 = '0xFA7df76E0CbdFFAF3277DC46c3d35629942712bC'
    key.Kroma_ERC1155 = '0xDA950D5C8AA60b6d3Dc432b8EFD494FBC8C48144'


    // key.Boba_TradeContract = '0xbDC1fd6F4cfaE3165A16397897B1C59DB15ED1F8'
    // key.Boba_ERC721 = '0x0708D75506D37c6B25f32C9DeD233A103B8005E3'
    // key.Boba_ERC1155 = '0xFA7df76E0CbdFFAF3277DC46c3d35629942712bC'

    key.Boba_TradeContract = '0xDA950D5C8AA60b6d3Dc432b8EFD494FBC8C48144'
    key.Boba_ERC721 = '0x0708D75506D37c6B25f32C9DeD233A103B8005E3'
    key.Boba_ERC1155 = '0xFA7df76E0CbdFFAF3277DC46c3d35629942712bC'

    key.Boba_Bnb_TradeContract = '0x0708D75506D37c6B25f32C9DeD233A103B8005E3'
    key.Boba_Bnb_ERC721 = '0xFA7df76E0CbdFFAF3277DC46c3d35629942712bC'
    key.Boba_Bnb_ERC1155 = '0xDA950D5C8AA60b6d3Dc432b8EFD494FBC8C48144'

    key.CHAIN_NAME = "XDC"
    key.RETROSTAKE_ADDRESS = "0x4101e8Ad5A9977dd23421F6404c6D0449be0e813"
    key.BASE_CHAIN_ID = 8453
    // key.BASE_CHAIN_ID = 84532
    key.SOP_CHAIN_ID = 1
    key.XDC_CHAIN_ID = 50
    key.KROMA_CHAIN_ID = 2358
    key.BOBA_CHAIN_ID = 288
    key.BOBA_BNB_CHAIN_ID = 56288

    // key.AdminAddress = '0xdb56f88ed86f75e29ee6eafd92e45acc7080f7af' // old address
    key.AdminAddress = '0x5007Fd154f412BE2781725E5e6B0072842Cbe09D'

    key.BaseApiKey = "GX4HDBZAPE12IDBBRASR4CIZTIFPNUX746"

    key.BOBA_BOBA_BNB = "1822fe31-2a7f-4de0-be53-215ad79dcb1d" //https://www.livecoinwatch.com/tools/api

}
else {





}
export default key;
