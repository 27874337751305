
import React, { useState, useEffect, useRef, useContext, } from "react";
import logo from './logo.svg';
import './App.css';
import Header from './app/Header';
import Footer from './app/Footer';
import Home from './app/Home';
import Create from './screens/Create';
import MyRoutes from './router/MyRoutes'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

function App() {
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MyRoutes />
      </LocalizationProvider>

    </>
  );
}

export default App;

